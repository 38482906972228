<template>
	<el-container style="position:absolute;left:0;right:0;top:0;bottom:0;overflow:hidden;" class="p-0">
		<el-main style="height:100%;" class="p-0">
			<vue-loading :active.sync="loading" color="#409EFF" :is-full-page="isFullPage" loader="bars" :z-index="zIndex"></vue-loading>
			<router-view></router-view>
		</el-main>
	</el-container>
</template>
<script>
	import VueLoading from 'vue-loading-overlay';
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		components: {
			VueLoading
		},
		provide() {
			return {
				loginLayout: this
			}
		},
		data() {
			return {
				isFullPage: false,
				zIndex: 2000,
				loading: false
			}
		},
		methods: {
			showLoading(isFullPage = false) {
				this.isFullPage = isFullPage
				this.zIndex = isFullPage ? 3000 : 2000
				this.loading = true
			},
			hideLoading() {
				this.loading = false
			}
		}
	}
</script>

<style>
</style>
